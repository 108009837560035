import { defineComponent, nextTick, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Card from '@/components/Card';

import MusicSongList from '@/components/Article/MusicSongList';
import MusicSongListPage from '@/components/Article/MusicSongListPage';

import FetchData from '@/components/FetchData';

type Author =
	| string
	| {
			readonly onlyid: string;
			title: string;
	  };

interface ListData {
	readonly onlyid: string | number;
	title: string;
	author: Author | Author[];
	duration: number | string;
	operate?: string;
	deterplay?: boolean;
}

export default defineComponent({
	components: { Card, MusicSongList, MusicSongListPage, FetchData },
	setup(props, { root }: any) {
		const categoryToText = () => {
			const { $route } = root;
			const { onlyid } = $route.params;
			const show: any = { original: '原创作品', part: '参与创作的作品', friends: '网友作品', cover: '翻唱/填词作品', favorite: '音乐收藏', karaoke: '伴奏' };
			return show[onlyid] || onlyid;
		};
		const listData: Ref<ListData[]> = ref([
			{
				onlyid: 'd_7eb5f051d1f4585c9c073776d46113337d84e99f',
				title: '我们的歌',
				author: '丽江小倩',
				duration: 327.01,
			},
			{
				onlyid: '2',
				title: '歌曲占位符',
				author: [
					'未知1',
					{
						onlyid: 'asudq23jhasdqw',
						title: '未知2',
					},
				],
				duration: 327.01,
			},
			{
				onlyid: '3',
				title: '无法播放的歌曲',
				author: '未知歌手',
				duration: 327.01,
				deterplay: true,
			},
		]);
		const touchData = (data: ListData[]) => (listData.value = data);
		//功能块，实现“换一批”刷新操作
		const isShowContent: Ref<boolean> = ref(true);
		const changeShowContent = () => {
			isShowContent.value = false;
			nextTick(() => {
				isShowContent.value = true;
			});
		};
		return { categoryToText, listData, touchData, isShowContent, changeShowContent };
	},
});
/***
 * 
 * 
 * 
 * 
 * 
 * 		const hld = (listData: ListData[]): ListData[] => [
			{
				onlyid: -1,
				title: '歌曲名',
				author: '歌手',
				duration: '时长',
				operate: '操作',
			},
			...listData,
		];		const activeIndex: Ref<number> = ref(-1);

const timeToString = (time: number): string =>
	`${Math.floor(Math.round(time) / 60)
		.toString()
		.padStart(2, '0')}:${(Math.round(time) % 60).toString().padStart(2, '0')}`;

 */
